export const apiUrl = () => {
  let url = "https://api.my-follower.com";

  if (process.env.NODE_ENV === "development") {
    url = "https://127.0.0.1:8002";
  }

  return url;
};

export const isBrowser = () => typeof window !== "undefined";
