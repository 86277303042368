import { isBrowser } from "./config";
import { Cookies } from "react-cookie-consent";

export function setToken(token, refreshToken = null) {
  if (!isBrowser()) return;
  removePro();
  Cookies.set("token", JSON.stringify({ t: token, rt: refreshToken }), {
    expires: 365,
    path: "/",
  });
}

export function setPro(val) {
  if (!isBrowser()) return;
  removePro();
  if (val === true) {
    Cookies.set("_", "lol");
  }
}

export function removePro() {
  if (!isBrowser()) return;
  Cookies.remove("_");
}

export const checkPro = () => {
  if (!isBrowser()) return false;
  if (Cookies.get("_") === "lol") return true;
  return false;
};

export function clearToken() {
  removePro();
  if (!isBrowser()) return;
  Cookies.remove("token");
  //console.log("cleared all data");
}

export const getToken = () => {
  if (!isBrowser()) return null;
  let ret = Cookies.get("token");
  if (!ret || ret === false || ret === "false") {
    return null;
  }
  ret = JSON.parse(ret);
  return ret.t;
};

export const isLoggedIn = () => {
  if (isBrowser()) {
    if (getToken()) {
      return true;
    }
  }
  return false;
};
