import React, { useState, useEffect } from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Menu,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { isBrowser } from "../lib/config";
import logoPNG from "../images/my-follower-logo.png";
import { getToken, checkPro, isLoggedIn } from "../lib/user.auth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Header = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const submenuIsOpen = Boolean(anchorEl);
  const [headersData, setHeadersData] = useState([
    {
      label: t("My accounts"),
      href: isLoggedIn() ? "/dashboard" : "/accounts",
    },
    {
      label: t("Insights"),
      href: [
        {
          label: "Facebook",
          href: "/app/facebook",
        },
        /*{
          label: "Instagram",
          href: "/app/instagram",
        },*/
      ],
    },
  ]);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      setAnchorEl(null);
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    if (getToken() && !checkPro()) {
      setHeadersData([
        ...headersData,
        {
          label: t("Get pro"),
          href: "/pricing",
        },
        {
          label: "Logout",
          href: "/logout",
        },
      ]);
    } else if (getToken()) {
      setHeadersData([
        ...headersData,
        {
          label: "Logout",
          href: "/logout",
        },
      ]);
    } else {
      setHeadersData([
        ...headersData,
        {
          label: "Roadmap",
          href: "/roadmap",
        },
      ]);
    }
    if (isBrowser()) {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({
              ...prevState,
              mobileView: true,
            }))
          : setState((prevState) => ({
              ...prevState,
              mobileView: false,
            }));
      };

      setResponsiveness();
      window.addEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Button
          {...{
            href: "/",
            style: {
              fontFamily: "Yusei Magic, sans-serif",
              fontWeight: 100,
              size: "18px",
              marginLeft: "-10px",
              textTransform: "none",
              paddingTop: 6,
            },
          }}
        >
          <img
            src={logoPNG}
            alt="My Follower Logo"
            height="50"
            width="229"
            style={{
              margin: 0,
              height: 50,
              width: 229,
            }}
          />
        </Button>
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          size="large"
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div>{getDrawerChoices()}</div>
        </Drawer>

        <div className="logoRight">
          <Link
            {...{
              href: "/",
              color: "inherit",
              style: {
                textDecoration: "none",
                marginRight: 10,
                float: "right",
                paddingTop: 10,
              },
            }}
          >
            <img
              src={logoPNG}
              alt="My Follower Logo"
              height="40"
              width="183"
              style={{
                margin: 0,
              }}
            />
          </Link>
        </div>
      </Toolbar>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLangClose = () => {
    setAnchorEl(null);
  };

  let index = 0;
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      if (label !== null && typeof href !== "object") {
        return (
          <Link
            {...{
              href: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label + ++index,
            }}
          >
            <MenuItem>{label}</MenuItem>
          </Link>
        );
      } else {
        return getSubMenu(label, href, "menuButtonsLeft");
      }
    });
  };

  function getSubMenu(label, href, menuButton2 = "menuButtonsOrig") {
    let ret = [];
    let indx = 0;
    ret.push(
      <Button
        {...{
          key: label,
          color: "inherit",
          onClick: handleClick,
          className: menuButton2,
        }}
      >
        {label} <ArrowDropDownIcon />
        <Menu
          keepMounted
          open={submenuIsOpen}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          key={"in" + ++indx}
        >
          {href.map(({ label, href }) => {
            if (label !== null && typeof href !== "object") {
              return (
                <Link
                  {...{
                    href: href,
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: label + ++indx,
                  }}
                  onMouseOut={() => setAnchorEl(false)}
                >
                  <MenuItem key={href + ++indx}>{label}</MenuItem>
                </Link>
              );
            }
            return null;
          })}
        </Menu>
      </Button>
    );

    return ret;
  }

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      if (label !== null && typeof href !== "object") {
        return (
          <Button
            {...{
              key: label + ++index,
              color: "inherit",
              href: href,
              className: "menuButtonOrig",
            }}
          >
            {label}
          </Button>
        );
      } else {
        return getSubMenu(label, href);
      }
    });
  };

  return (
    <AppBar>
      <div className="header-center">
        {isBrowser() ? mobileView ? displayMobile() : displayDesktop() : <></>}
      </div>
    </AppBar>
  );
};

export default Header;
