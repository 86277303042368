import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  useI18next,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next";

import Header from "./header";
import CookieConsent from "react-cookie-consent";
import { Grid } from "@mui/material";
import gatsbyjs from "../images/gatsby-icon.png";
import mui from "../images/mui.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import * as Sentry from "@sentry/react";
import { checkPro, getToken } from "../lib/user.auth";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://819228d570f1418b97cabc207164ff32@o505565.ingest.sentry.io/5594132",
  });
}

const Layout = ({ children }) => {
  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();
  const context = React.useContext(I18nextContext);

  useEffect(() => {
    return () => {
      return null;
    };
  }, []);

  return (
    <div className="content-wrap">
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 100,
        }}
      >
        <main>{children}</main>
      </div>
      <div className="footer-wrap">
        <footer
          style={{
            margin: "0 auto",
            maxWidth: "960px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={7}>
              {new Date().getFullYear()} ©{" "}
              <a
                href="https://palmomedia.de"
                rel="noreferrer"
                className="changeColor"
                target="_blank"
              >
                palmomedia.de
              </a>
              &nbsp;|&nbsp;Frankenberg/Sa.&nbsp;|&nbsp;
              <a href="/imprint" rel="noreferrer" target="_self">
                {t("Imprint")}
              </a>
              <br />
              {t("Follow us on")}{" "}
              <a
                href="https://www.facebook.com/MyFollower"
                rel="noreferrer"
                target="_blank"
                title="Facebook"
                className="changeColor"
              >
                <FacebookIcon style={{ marginBottom: -8 }} /> facebook
              </a>{" "}
              <a
                href="https://x.com/MyFollowerCom"
                rel="noreferrer"
                target="_blank"
                title="x formerly twitter"
                className="changeColor"
              >
                <img
                  src="https://palmomedia.de/twitter-x-logo2.png"
                  height="20"
                  alt="X"
                  style={{ marginBottom: -5 }}
                />{" "}
                x (twitter)
              </a>{" "}
              <a
                href="https://www.instagram.com/myfollowercom/"
                rel="noreferrer"
                target="_blank"
                title="Instagram"
                className="changeColor"
              >
                <InstagramIcon style={{ marginBottom: -8 }} /> instagram
              </a>
              <br />
              <br />
              Built with{" "}
              <a
                href="https://www.gatsbyjs.org"
                rel="noreferrer"
                target="_blank"
                className="changeColor"
              >
                <img
                  alt="gatsbyjs"
                  src={gatsbyjs}
                  height="14"
                  style={{ margin: 0 }}
                  title="GatsbyJs"
                  valign="middle"
                />{" "}
                GatsbyJS
              </a>{" "}
              and&nbsp;&nbsp;
              <a
                href="https://mui.com/"
                rel="noreferrer"
                target="_blank"
                className="changeColor"
              >
                <img
                  alt="MUI"
                  src={mui}
                  height="20"
                  style={{ margin: 0, marginBottom: -5 }}
                  title="MUI"
                  valign="middle"
                />{" "}
                Material UI
              </a>
              <br />
              <br />
            </Grid>
            <Grid item xs={6} sm={3}>
              <a href="/app/facebook" rel="noreferrer" target="_self">
                {t("Facebook insights")}
              </a>
              <br />
              <a href="/how-it-works-(faq)" rel="noreferrer" target="_self">
                {t("How it works")}
              </a>
              <br />
              {!checkPro() && (
                <>
                  <a href="/pricing" rel="noreferrer" target="_self">
                    {getToken() ? t("Get pro") : t("Pricing")}
                  </a>
                  <br />
                </>
              )}
              {!getToken() && (
                <>
                  <a href="/sign-up" rel="noreferrer" target="_self">
                    {t("Sign up")}
                  </a>
                  <br />
                  <a href="/login" rel="noreferrer" target="_self">
                    {t("Login")}
                  </a>
                  <br />
                </>
              )}
            </Grid>
            <Grid item xs={6} sm={2}>
              {languages.map((lng) => (
                <span key={lng}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage(lng);
                    }}
                  >
                    {context.language === lng ? (
                      <strong className="changeColor">{t(lng)}</strong>
                    ) : (
                      t(lng)
                    )}
                  </a>
                  <br />
                </span>
              ))}
              <br />
            </Grid>
          </Grid>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText="Sure man, i accept cookies!!"
          cookieName="cookie_gdpr"
          style={{ background: "#f3f3f3", color: "#000" }}
          buttonStyle={{
            color: "#fff",
            background: "#de354c",
            borderRadius: "5px",
          }}
          location="bottom"
          expires={150}
        >
          <h3>
            <strong>Cookie Policy</strong>
          </h3>
          <p>This website uses cookies to enhance the user experience.</p>
          <p
            style={{
              fontSize: "14px",
              marginTop: "-10px",
              marginBottom: 0,
            }}
          >
            We also use cookies for to provide social media{" "}
            <strong>features</strong> and to{" "}
            <strong>analyse our traffic</strong>. We also share information
            about your use of our site with our social media, advertising and
            analytics partners who may combine it with other information that
            you’ve provided to them or that they’ve collected from your use of
            their services.
          </p>
        </CookieConsent>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
